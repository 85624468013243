import { useEffect } from 'react';
import Frontpage from './components/frontpage';
import Navigation from './components/navigation';

function App() {

  return (
    <div className="">
      <Navigation />
      <Frontpage />
      <div className='hidden md:block md:visible fixed bottom-0 right-4 w-80'>
        <iframe
          src="https://open.spotify.com/embed/track/07ytuP82Fk2DTXochBvszg?&theme=0"
          height="152"
          width="100%"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default App;
