import Banner from "./banner";
import Socials from "./socials";

export default function Frontpage() {

    return (
        <>
            <Banner />
            <Socials />
        </>
    )

}