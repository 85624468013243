import { useState } from "react"
import logo from "../../assets/images/logo_white.png"

export default function Navigation() {

    const [navOpen, setNavOpen] = useState(false)

    const spotifyUrl = "https://open.spotify.com/artist/4YmPLEefyW87OctGgJ0QlE?si=7D9nc0YnS7-Uw7GxhOjX5A";
    const youtubeUrl = "https://www.youtube.com/channel/UCpx9YjkYLCpE5ALWzHa7Dyw";
    const appleUrl = "https://music.apple.com/us/artist/bloodrunk/1600713787";

    return (
        <section className="absolute overflow-hidden w-full">
            <nav className="relative py-8 lg:py-0 bg-transparent border-b">
                <div className="container mx-auto px-4">
                    <div className="flex justify-between">
                        <div className="pr-14 flex items-center lg:border-r">
                            <a className="inline-block text-xl text-white font-medium font-heading" href="#">
                                <img className="h-12" width="auto" src={logo} alt=""/>
                            </a>
                        </div>
                        <div className="hidden lg:block ml-auto mr-10 py-8">
                            <ul className="flex items-center">
                                <li className="mr-12"><a className="font-bold text-gray-500 hover:text-gray-400" target="_blank" href={appleUrl}>Apple Music</a></li>
                                <li className="mr-12"><a className="font-bold text-gray-500 hover:text-gray-400" target="_blank" href={spotifyUrl}>Spotify</a></li>
                                <li className="mr-12"><a className="font-bold text-gray-500 hover:text-gray-400" target="_blank" href={youtubeUrl}>Youtube</a></li>
                            </ul>
                        </div>
                        <button className="navbar-burger lg:hidden self-center" onClick={() => setNavOpen(!navOpen)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="6" width="24" height="2" fill="white"></rect><rect y="11" width="24" height="2" fill="white"></rect><rect y="16" width="24" height="2" fill="white"></rect></svg>
                        </button>
                    </div>
                </div>
            </nav>
            <div className={"navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50" + (navOpen ? "" : " hidden")}>
                <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" onClick={() => setNavOpen(false)}/>
                <nav className="relative flex flex-col py-8 px-10 w-full h-full bg-black border-r overflow-y-auto">
                    <a className="inline-block text-xl text-white font-medium font-heading mb-16 md:mb-32" href="#">
                        <img className="h-8" width="auto" src="boldui-assets/logo/logo-boldui-light.svg" alt="" />
                    </a>
                    <ul className="mb-32">
                        <li className="mb-10">
                            <a className="flex items-center" target="_blank" href={appleUrl}>
                                <span className="mr-3 text-lg text-white">Apple Music</span>
                                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#5d1000"></path>
                                </svg>
                            </a>
                        </li>
                        <li className="mb-10">
                            <a className="flex items-center" target="_blank" href={spotifyUrl}>
                                <span className="mr-3 text-lg text-white">Spotify</span>
                                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#5d1000"></path>
                                </svg>
                            </a>
                        </li>
                        <li className="mb-10">
                            <a className="flex items-center" target="_blank" href={youtubeUrl}>
                                <span className="mr-3 text-lg text-white">Youtube</span>
                                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#5d1000"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    <p className="bottom-3 text-sm text-gray-500">All rights reserved © Bloodrunk 2023</p>
                </nav>
            </div>
        </section>
    )

}