import avatar from "../../../assets/images/avatar.jpg";
import banner from "../../../assets/images/banner.jpg";
import apple_icon from "../../../assets/images/apple_icon_black.png";
import spotify_icon from "../../../assets/images/spotify_icon_black.png";
import youtube_icon from "../../../assets/images/youtube_icon_black.png";

export default function Banner() {

    const spotifyUrl = "https://open.spotify.com/track/07ytuP82Fk2DTXochBvszg?si=fe798ee95b10448e";
    const youtubeUrl = "https://youtu.be/kE7KDqIbg38?si=YYPv_wJD_yndL3xk";
    const appleUrl = "https://music.apple.com/us/album/poison/1716227383?i=1716227384";

    return (
        <section className="min-h-screen bg-black overflow-hidden flex justify-center items-center">
            <div className="mt-24  md:mt-0 w-full flex flex-wrap -mx-4">
                <div className="w-full md:w-1/2 lg:w-2/3 px-4 flex justify-center">
                    <img className={"h-64 md:h-full flex items-center justify-end rounded-r-full bg-no-repeat bg-cover lg:hidden"} src={avatar} />
                    <img className={"h-64 md:h-full flex items-center justify-end rounded-r-full bg-no-repeat bg-cover hidden lg:flex object-cover"} src={banner}/>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 self-center">
                    <div className="flex justify-center px-4 py-20 lg:py-40">
                        <div>
                            <h3 className="text-sm text-gray-500">
                                <span className="font-bold">New single</span>
                            </h3>
                            <h2 className="mb-6 text-4xl text-white uppercase font-heading">Poison</h2>
                            <h3 className="mb-8 text-base text-gray-500">
                                <span className="font-bold">Now available worldwide</span>
                            </h3>
                            <div className="flex flex-row space-x-1">
                                <a className="inline-flex items-center py-4 px-6 rounded-full bg-secondary hover:bg-primary transform duration-200" href={appleUrl} target="_blank">
                                    <img className="h-6" src={apple_icon} />
                                </a>
                                <a className="inline-flex items-center py-4 px-6 rounded-full bg-secondary hover:bg-primary transform duration-200" href={spotifyUrl} target="_blank">
                                    <img className="h-6" src={spotify_icon} />
                                </a>
                                <a className="inline-flex items-center py-4 px-6 rounded-full bg-secondary hover:bg-primary transform duration-200" href={youtubeUrl} target="_blank">
                                    <img className="h-6" src={youtube_icon} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}