import spotify from "../../../assets/images/spotify_white.png";
import apple from "../../../assets/images/apple_white.png";
import youtube from "../../../assets/images/youtube_white.png";
import soundcloud from "../../../assets/images/soundcloud_white.png";
import beatport from "../../../assets/images/beatport_white.png";
import amazon from "../../../assets/images/amazon_white.png";

export default function Socials() {

    const spotifyUrl = "https://open.spotify.com/artist/4YmPLEefyW87OctGgJ0QlE?si=7D9nc0YnS7-Uw7GxhOjX5A";
    const youtubeUrl = "https://www.youtube.com/channel/UCpx9YjkYLCpE5ALWzHa7Dyw";
    const appleUrl = "https://music.apple.com/us/artist/bloodrunk/1600713787";
    const amazonUrl = "https://music.amazon.in/artists/B09NQHYM8J/bloodrunk";
    const soundcloudUrl = "https://soundcloud.com/getblooddrunk";
    const beatportUrl = "https://www.beatport.com/artist/bloodrunk/1030906";

    return (
        <section className="py-20 bg-black overflow-x-hidden min-h-screen flex items-center w-full">
            <div className="container mx-auto px-4">
                <div className="mb-8 max-w-xl mx-auto text-center">
                    <span className="text-lg font-semibold text-secondary">Join the movement</span>
                    <h2 className="mt-6 text-3xl text-white font-heading">Let's get blooddrunk.</h2>
                </div>
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-4 cursor-pointer">
                        <div className="relative h-64 w-full flex items-center justify-center">
                            <a href={soundcloudUrl} target="_blank">
                                <img className="h-10" src={soundcloud} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-4">
                        <div className="relative h-64 w-full flex items-center justify-center">
                            <a href={youtubeUrl} target="_blank">
                                <img className="h-10" src={youtube} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-4">
                        <div className="relative h-64 w-full flex items-center justify-center">
                            <a href={spotifyUrl} target="_blank">
                                <img className="h-10" src={spotify} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-4">
                        <div className="relative h-64 w-full flex items-center justify-center">
                            <a href={appleUrl} target="_blank">
                                <img className="h-10" src={apple} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-4">
                        <div className="relative h-64 w-full flex items-center justify-center">
                            <a href={beatportUrl} target="_blank">
                                <img className="h-10" src={beatport} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-4">
                        <div className="relative h-64 w-full flex items-center justify-center">
                            <a href={amazonUrl} target="_blank">
                                <img className="h-10" src={amazon} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}